:root {
  --spacePart: 13px;
}
@media (max-width: 1023px) {
  :root {
    --spacePart: 13px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 13px;
  }
}
:root {
  --spaceUnit: 25px;
}
@media (max-width: 1023px) {
  :root {
    --spaceUnit: 25px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 25px;
  }
}
:root {
  --spaceTotal: 38px;
}
@media (max-width: 1023px) {
  :root {
    --spaceTotal: 38px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 38px;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #000;
  line-height: 1.44444444;
  cursor: pointer;
  color: #fff;
  padding: 11px 40px 9px;
  min-width: 200px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-family: 'CeraProMedium', sans-serif;
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-size: 0 100%;
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-image: linear-gradient(to right, #EF6100, #EF6100);
  transition: all 0.6s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.button:hover,
.button:focus {
  color: #fff;
  background-size: 100% 100%;
}
div.navi {
  float: left;
}
div.navi > .item {
  float: left;
}
div.navi > .item > .menu {
  display: block;
  text-decoration: none;
  color: #fff;
}
@media (min-width: 1799px) {
  .togglenavigation,
  .mobile-navigation {
    display: none;
  }
}
.topnavi div.sub1 > .item {
  border-left: 1px solid #fff;
}
.topnavi div.sub1 > .item.exit {
  border-right: 1px solid #fff;
}
.topnavi div.sub1 > .item > .menu {
  text-transform: uppercase;
  letter-spacing: 1px;
  text-decoration: none;
  padding: 6px 56px 4px 20px;
  font-size: 16px;
  line-height: 1.625;
}
.topnavi div.sub1 > .item > .menu:hover,
.topnavi div.sub1 > .item > .menu:focus,
.topnavi div.sub1 > .item > .menu.path {
  background-color: #000;
}
.cb-navi-sticky div.sub2 {
  margin-left: clamp(15.625rem, 10.612rem + 5.53vw, 17.25rem);
}
.cb-navi-sticky div.sub2 > .item {
  margin: 0 clamp(0.625rem, -0.918rem + 1.7vw, 1.125rem);
}
.cb-navi-sticky div.sub2 > .item.init {
  margin-left: 0;
}
.cb-navi-sticky div.sub2 > .item.exit {
  margin-right: 0;
}
.cb-navi-sticky div.sub2 > .item:hover > .menu,
.cb-navi-sticky div.sub2 > .item:focus > .menu {
  color: #EF6100;
}
.cb-navi-sticky div.sub2 > .item > .menu {
  font-size: clamp(1.125rem, 0.354rem + 0.85vw, 1.375rem);
  line-height: 1.18181818;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 37px 0;
  transition: padding 0.3s;
  position: static;
}
.cb-navi-fixed .cb-navi-sticky div.sub2 > .item > .menu {
  padding-top: 22px;
  padding-bottom: 22px;
}
.cb-navi-sticky div.sub2 > .item > .menu.path {
  color: #EF6100;
}
.cb-navi-sticky div.sub3 {
  position: absolute;
  top: 100%;
  background-color: #000;
  width: 100vw;
  left: 50%;
  margin-left: -50vw;
  box-sizing: border-box;
  padding: 50px calc((100vw - 1800px) / 2) 130px;
  flex-wrap: wrap;
  display: none;
}
@media (max-width: 1920px) {
  .cb-navi-sticky div.sub3 {
    padding-left: 60px;
    padding-right: 60px;
  }
}
.cb-navi-sticky div.sub3 > .item {
  margin-left: 100px;
}
.cb-navi-sticky div.sub3 > .item.init {
  margin-left: 276px;
}
.cb-navi-sticky div.sub3 > .item > .menu {
  font-size: 22px;
  line-height: 1.18181818;
  font-weight: normal;
  font-family: 'CeraProBold', sans-serif;
  -webkit-font-smoothing: antialiased;
}
.cb-navi-sticky div.sub3 > .item > .menu:hover,
.cb-navi-sticky div.sub3 > .item > .menu:focus,
.cb-navi-sticky div.sub3 > .item > .menu.path {
  color: #EF6100;
}
.cb-navi-sticky div.sub2 > .item:hover div.sub3 {
  display: flex;
}
.cb-navi-sticky div.sub4 {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.cb-navi-sticky div.sub4 > .item {
  margin: 10px 0;
}
.cb-navi-sticky div.sub4 > .item.exit {
  margin-bottom: 0;
}
.cb-navi-sticky div.sub4 > .item > .menu {
  float: left;
  -webkit-font-smoothing: antialiased;
  font-size: 22px;
  line-height: 1.18181818;
}
.cb-navi-sticky div.sub4 > .item > .menu:hover,
.cb-navi-sticky div.sub4 > .item > .menu:focus,
.cb-navi-sticky div.sub4 > .item > .menu.path {
  color: #EF6100;
}
@media (min-width: 1799px) {
  .homelink {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s;
    margin: 0;
  }
  .homelink .logo--text {
    transition: all 0.4s;
  }
  .cb-navi-fixed .homelink {
    height: 46px;
  }
  .cb-navi-fixed .homelink .logo--text {
    height: 0;
    opacity: 0;
  }
}
.desk {
  max-width: calc(100% - 120px);
}
.navigation {
  position: relative;
}
.navigation .desk {
  min-height: 100px;
  transition: all 0.3s;
}
.navigation.cb-navi-fixed {
  position: fixed;
}
.navigation.cb-navi-fixed .desk {
  min-height: 70px;
}
@media (max-width: 1279px) {
  div.sub1 {
    margin-left: 0;
  }
  div.sub1 > .item > .menu {
    padding-right: 18px;
    padding-left: 18px;
  }
  div.sub2 > .item > .menu {
    font-size: 18px;
    line-height: 1.44444444;
  }
  div.sub3 {
    justify-content: center;
  }
  div.sub3 > .item.init {
    margin-left: 0;
  }
  div.sub3 > .item > .menu {
    font-size: 18px;
  }
  div.sub4 > .item > .menu {
    font-size: 18px;
  }
  .services .sep {
    margin: 0 5px;
  }
  .sb-slider__subtitle {
    font-size: 60px;
  }
  .sb-slider__iconwidth {
    width: 140px;
  }
  .footpart {
    width: 320px;
  }
}
@media (min-width: 1920px) {
  .cb-layout3 .section--main:before {
    border-width: 560px 1920px 0 0;
  }
  #expo:before {
    border-width: 0 960px 280px 0;
  }
  #expo:after {
    border-width: 0 0 280px 960px;
  }
  .cb-index-all {
    bottom: 306px;
  }
  .triangle {
    height: 250px;
  }
  .triangle:after {
    border-width: 0 0 560px 1920px;
  }
  .sb-slider__triangle--top {
    border-width: 560px 0 0 1920px;
  }
  .sb-slider__triangle--bottom {
    border-width: 0 0 560px 1920px;
  }
  .footspickel {
    margin-top: -560px;
  }
  .footspickel-triangle--white {
    border-width: 560px 1920px 0 0;
    margin-bottom: -560px;
  }
  .trianglewidth {
    margin-top: -280px;
  }
  .trapezoid {
    height: 800px;
  }
  .trapezoid__triangle--top {
    border-width: 0 0 560px 1920px;
  }
  .trapezoid__triangle--bottom-left {
    border-width: 280px 0 0 960px;
  }
  .trapezoid__triangle--bottom-right {
    border-width: 280px 960px 0 0;
  }
  .section--beta .area .flat {
    width: 1920px !important;
    margin-left: -960px !important;
    height: 1370px;
  }
  .section--beta .area .flat .pict.tall .cb-image-figure:before {
    border-width: 560px 1920px 0 0;
  }
  .section--beta .area .flat .pict.tall .cb-image-figure:after {
    border-width: 0 1920px 560px 0;
  }
  .section--beta .area .flat.slim .pict.tall .cb-image-figure:before {
    border-width: 560px 0 0 1920px;
  }
  .section--beta .area .flat.slim .pict.tall .cb-image-figure:after {
    border-width: 0 0 560px 1920px;
  }
}
.section--alpha .area {
  width: 100%;
}
.section--alpha .area > .unit {
  margin-right: 1.66666667%;
  margin-left: 1.66666667%;
  width: 96.66666667%;
}
.section--alpha .area .cb-album .body,
.section--alpha .area .head,
.section--alpha .area .foot,
.section--alpha .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--alpha .area > .slim {
  width: 30%;
}
.section--alpha .area > .slim .head,
.section--alpha .area > .slim .foot,
.section--alpha .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--alpha .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--alpha .area > .slim .tiny {
  width: 100%;
}
.section--beta .area {
  width: 100%;
}
.section--beta .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--beta .area .cb-album .body,
.section--beta .area .head,
.section--beta .area .foot,
.section--beta .area .part {
  margin-right: 3.52564103%;
  margin-left: 3.52564103%;
  width: 92.94871795%;
}
.section--beta .area .tiny {
  width: 42.94871795%;
}
.section--beta .area > .slim {
  width: 50%;
}
.section--beta .area > .slim .head,
.section--beta .area > .slim .foot,
.section--beta .area > .slim .part {
  margin-right: 7.05128205%;
  margin-left: 7.05128205%;
  width: 85.8974359%;
}
.section--beta .area > .slim.cb-album .body {
  margin-right: 7.05128205%;
  margin-left: 7.05128205%;
  width: 85.8974359%;
}
.section--beta .area > .slim .tiny {
  width: 85.8974359%;
}
.section--gamma .area {
  width: 100%;
}
.section--gamma .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.section--gamma .area .cb-album .body,
.section--gamma .area .head,
.section--gamma .area .foot,
.section--gamma .area .part {
  margin-right: 1.66666667%;
  margin-left: 1.66666667%;
  width: 96.66666667%;
}
.section--gamma .area .tiny {
  width: 30%;
}
.section--gamma .area > .slim {
  width: 33.33333333%;
}
.section--gamma .area > .slim .head,
.section--gamma .area > .slim .foot,
.section--gamma .area > .slim .part {
  margin-right: 5%;
  margin-left: 5%;
  width: 90%;
}
.section--gamma .area > .slim.cb-album .body {
  margin-right: 5%;
  margin-left: 5%;
  width: 90%;
}
.section--gamma .area > .slim .tiny {
  width: 90%;
}
.section--alpha .area {
  width: 103.44827586%;
  margin-left: -1.72413793%;
}
.section--beta .area {
  width: 107.5862069%;
  margin-left: -3.79310345%;
}
.section--gamma .area {
  width: 103.44827586%;
  margin-left: -1.72413793%;
}
.section--alpha .area .unit.pure.slim {
  width: 96.66666667%;
}
.section--alpha .area .seam.wide .part {
  width: 94.81865285%;
  margin-left: 2.59067358%;
  margin-right: 2.59067358%;
}
.section--alpha .area .seam.slim .part {
  width: 83.24022346%;
  margin-left: 8.37988827%;
  margin-right: 8.37988827%;
}
.section--beta .area .seam {
  margin-left: 3.52564103%;
  margin-right: 3.52564103%;
}
.section--beta .area .seam.wide {
  width: 92.94871795%;
}
.section--beta .area .seam.wide .part {
  margin-left: 2.5862069%;
  margin-right: 2.5862069%;
  width: 94.82758621%;
}
.section--beta .area .seam.slim {
  width: 42.94871795%;
}
.section--beta .area .seam.slim .part {
  margin-left: 5.59701493%;
  margin-right: 5.59701493%;
  width: 88.80597015%;
}
.section--gamma .area .seam {
  width: 100%;
}
.section--gamma .area .seam .part {
  margin-left: 1.66666667%;
  margin-right: 1.66666667%;
}
.section--gamma .area .seam .part.tall {
  float: right;
  width: 63.33333333%;
}
.section--gamma .area .seam .part.tiny {
  width: 30%;
}
.section--one .area .unit.seam.slim {
  width: calc((100% / 3) - 40px);
}
.section--one .area .edge {
  width: calc((100% / 3) - 40px);
}
/*# sourceMappingURL=./screen-large.css.map */